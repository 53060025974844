var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"light":"","persistent":"","max-width":"900px"},model:{value:(_vm.reportByPartnerDialog),callback:function ($$v) {_vm.reportByPartnerDialog=$$v},expression:"reportByPartnerDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2"},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v(" "+_vm._s(_vm.headerDetails.partner_name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleReportByPartnerDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),(_vm.tab == 0 ? true : false)?_c('v-card-subtitle',[_vm._v(" Boletos do períoddo de "+_vm._s(_vm._f("formatDate")(_vm.headerDetails.start_date))+" a "+_vm._s(_vm._f("formatDate")(_vm.headerDetails.end_date))+" ")]):_vm._e(),(_vm.tab == 1 ? true : false)?_c('v-card-subtitle',{staticClass:"d-flex justify-space-between boxGrey font-weight-bold px-8 py-4 mb-4"},[_c('v-btn',{staticClass:"pr-5",staticStyle:{"height":"22px"},attrs:{"x-small":"","color":"primary"},on:{"click":function($event){_vm.tab = 0}}},[_c('v-icon',{staticStyle:{"margin-bottom":"3px"},attrs:{"small":"","left":""}},[_vm._v("reply")]),_vm._v(" Voltar ")],1),_vm._v(" Boletos do dia "+_vm._s(_vm._f("formatDate")(_vm.billetDay))+" ")],1):_vm._e(),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"no-data-text":"Não há boletos registrados","headers":_vm.headers,"items":_vm.billetsByPartner,"items-per-page":5},scopedSlots:_vm._u([{key:"item.summarization_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.summarization_date))+" ")]}},{key:"item.summarized_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.summarized_value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pr-4",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.getBilletsBydate(item.summarization_date)}}},[_c('span',{staticClass:"mr-2"},[_vm._v("+")]),_vm._v(" Detalhes ")])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"no-data-text":"Não há boletos registrados","headers":_vm.headersByDate,"items":_vm.billetsByDate,"items-per-page":5},scopedSlots:_vm._u([{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.due_date))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.payment_date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.value))+" ")]}}])})],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleReportByPartnerDialog}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }