<template>
  <v-row no-gutters class="px-10 pt-10 pb-5">
    <v-col cols="12" md="2" class="pr-8 pl-2">
      <v-img class="mt-5" :src="dtvGo" max-width="200"></v-img>
    </v-col>
    <v-col cols="12" md="2" class="pl-2 pr-3">
      <v-menu v-model="initialDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field clearable v-model="initialDateFormated" label="Data inicial" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker no-title v-model="initialDate" @input="initialDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="2" class="pl-2 pr-3">
      <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field clearable v-model="endDateFormated" label="Data final" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker no-title v-model="endDate" @input="endDateMenu = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="2" class="mt-3">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="getReport" class="ma-2" x-small fab color="primary">
            <v-icon small>search</v-icon>
          </v-btn>
        </template>
        <span>Pesquisar</span>
      </v-tooltip>
    </v-col>
    <v-spacer></v-spacer>
    <v-btn v-if="!abranetExcel" @click="generateExcel(false)" small color="success" dark class="mr-2 mt-5">
      Gerar planilha<v-icon small right> assignment </v-icon>
    </v-btn>
    <v-btn v-if="!abranetExcel" @click="generateExcel(true)" small color="teal darken-2" dark class="ml-5 mr-2 mt-5">
      Extrato DTVgo<v-icon small right> receipt_long </v-icon>
    </v-btn>
    <v-col cols="12" md="12" class="mt-5" v-if="showDetailsReport">
      <v-alert icon="today" dense text type="success" class="">
        <v-row no-gutters class="grey--text text--darken-3">
          <v-col cols="12" md="8" class="">
            Relatório de pagamento DirectvGo, período de referência -
            <strong>{{ initialDateFormated }} a {{ endDateFormated }}</strong>
          </v-col>
          <v-spacer></v-spacer>
          <!-- class="success--text" -->
          <span><small>Total: </small>
            <strong>R$ {{ subscriberSum | formatPrice }}</strong></span>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="12" md="12" class="mt-3 px-3">
      <h3>Subscrições relativas ao período de referência</h3>
    </v-col>
  </v-row>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
import * as moment from "moment";
export default {
  methods: {
    generateExcel(extract) {
      //console.log(this.initialDateFormated, this.endDateFormated);
      if (!this.initialDateFormated || !this.endDateFormated) {
        return;
      }

      var obj = {
        url: "/api/v1/subscriptionAPI/partner/report/xls",
        query: {
          // service_id: 2,
          prefix: "dtvgo",
          partner_id: this.externalPartnerId ? this.externalPartnerId : localStorage.getItem("partner_id"),
          start_date: this.initialDate,
          end_date: this.endDate,
          new_report: extract ? true : false
        },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response);
        if (!response) {
          return;
        }
        let obj = {};
        if (!response.data.link) {
          obj = {
            message: "Não existem dados para o período selecionado.",
            title: "Período inválido!",
            callback: (_) => { },
            type: "warning",
            btConfirm: { name: "Ok", show: true },
            btCancel: { name: "Não", show: false },
          };
        } else {
          obj = {
            message: "Deseja salvar a planilha?",
            title: "Planilha criada com sucesso!",
            callback: (_) => {
              window.open(response.data.link);
            },
            type: "success",
            btConfirm: { name: "Sim", show: true },
            btCancel: { name: "Não", show: true },
          };
        }
        this.$store.commit("confirmMessage/showConfirmMessage", obj);
      });
    },
    getReport() {
      if (!this.initialDateFormated || !this.endDateFormated) {
        return;
      }
      this.getSubscribersReport(this.initialDate, this.endDate);
    },
  },
  props: {
    abranetExcel: Boolean,
    getSubscribersReport: Function,
    showDetailsReport: Boolean,
    subscriberSum: String,
    externalPartnerId: Number
  },
  data() {
    return {
      dtvGo: require("@/assets/directvgo.svg"),
      initialDate: "",
      initialDateMenu: false,
      endDate: "",
      endDateMenu: false,
    };
  },
  computed: {
    initialDateFormated: {
      get: function () {
        let date = moment(this.initialDate).format("DD/MM/YYYY");
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.initialDate = "";
        }
        let date = moment(this.initialDate).format("DD/MM/YYYY");
        if (date == "Invalid date") {
          return null;
        }
      },
    },
    endDateFormated: {
      get: function () {
        let date = moment(this.endDate).format("DD/MM/YYYY");
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.endDate = "";
        }
        let date = moment(this.endDate).format("DD/MM/YYYY");
        if (date == "Invalid date") {
          return null;
        }
      },
    },
    // expirationDateFormated() {
    //   let date = moment(this.expirationDate).format("DD/MM/YYYY");
    //   return date == "Invalid date" ? null : date;
    // },
  },
};
</script>

<style></style>