<template>
  <v-row justify="center">
    <v-dialog v-model="spreadsheetScreenDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar class="denseToolBar" dense dark color="primary">
          <v-toolbar-title>Relatório mensal</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="px-8" small icon dark @click="toogleSpreadsheetScreenDialog">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </template>
              <span class="miniTtip">Fechar</span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <TooBarDtvGo :abranetExcel="abranetExcel" :externalPartnerId="externalPartnerId"
          :getSubscribersReport="getSubscribersReport" :showDetailsReport="showDetailsReport"
          :subscriberSum="subscriberSum" />
        <GridSreadsheet :subscribers="subscribers" />
        <!-- <v-divider></v-divider> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TooBarDtvGo from "./TooBarDtvGo.vue";
import GridSreadsheet from "./GridSreadsheet.vue";
export default {
  watch: {
    spreadsheetScreenDialog(v) {
      if (!v) {
        this.subscribers = []
        this.showDetailsReport = false
      }
    }
  },
  components: { GridSreadsheet, TooBarDtvGo },
  props: {
    abranetExcel: Boolean,
    externalUrl: String,
    externalPartnerId: Number,
    spreadsheetScreenDialog: Boolean,
    toogleSpreadsheetScreenDialog: Function,
  },
  methods: {
    getSubscribersReport(initialDate, endDate) {
      let query = {
        // service_id: 2,
        prefix: "dtvgo",
        partner_id: this.externalPartnerId ? this.externalPartnerId : localStorage.getItem("partner_id"),
        start_date: initialDate,
        end_date: endDate,
      };
      var obj = {
        url: this.externalUrl ? this.externalUrl : "/api/v1/subscriptionAPI/partner/get-report",
        query: query,
        method: "post",
      };
      //console.log(obj);
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log(response.data.Total);
        if (response.data.reports.length) {
          this.showDetailsReport = true;
          this.subscriberSum = response.data.Total
            ? response.data.Total
            : "0.00";
        }
        this.subscribers = [
          //...response.data.reports,
          ...response.data.reports.map((item) => {
            item.addPackage.map((pack) => {
              pack.tot = item.total;
              return pack;
            });
            //item.sumPack = item.addPackage
            // .map((itemPack) =>
            //   parseFloat(
            //     item.discountValue ? item.discountValue : itemPack.totalValue
            //   )
            // )
            // .reduce((prev, curr) => prev + curr, 0);
            return item;
          }),
        ];
        // console.log('0000',this.subscribers)
      });
    },
  },
  data() {
    return {
      subscriberSum: "",
      showDetailsReport: false,
      subscribers: [],
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>

<style>
.denseToolBar .v-toolbar__content {
  padding-right: 0;
}
</style>
