<template>
    <div class="pl-16 pr-2 mb-3">
        <v-card :min-height="windowH" class="mx-auto mt-3 bgTransparente" max-width="calc(100% - 10px)" elevation="0">
            <v-card-title class="ml-10 pt-4 pb-8 pl-0 black--text">
                <v-tabs v-model="tab">
                    <v-tab :disabled="disabTittles">Relatório gerencial de boletos</v-tab>
                    <v-tab>Relatório gerencial de subscrições</v-tab>
                    <v-tab>Protocolo de envio conciliação DGO</v-tab>
                </v-tabs>
            </v-card-title>

            <v-tabs-items v-model="tab">

                <v-tab-item>

                    <v-row no-gutters class="px-10 pt-0 pb-5">
                        <v-col cols="12" md="3" class="pl-2 pr-3">
                            <v-menu v-model="initialDateMenu" :close-on-content-click="false" transition="scale-transition"
                                offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable v-model="initialDateFormated" label="Data inicial" readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker :max="disableToday" no-title v-model="initialDate"
                                    @input="initialDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3" class="pl-2 pr-3">
                            <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                                offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable v-model="endDateFormated" label="Data final" readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker no-title v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2" class="mt-3">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" @click="getReport" class="ma-2" x-small fab
                                        color="primary">
                                        <v-icon small>search</v-icon>
                                    </v-btn>
                                </template>
                                <span>Pesquisar</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <div class="px-10">
                        <v-data-table no-data-text="Não há boletos registrados para o período" :headers="headers"
                            :items="billets" :items-per-page="5" class="elevation-0">
                            <template v-slot:item.partner_name="{ item }">
                                <div>
                                    {{ item.partner_name }}
                                </div>
                                <div class="caption">{{ item.partner_document | formatCnpjCpf }}</div>
                            </template>

                            <template v-slot:item.title_total_value="{ item }">
                                R$ {{ item.title_total_value | formatPrice }}
                            </template>

                            <template v-slot:item.start_date="{ item }">
                                {{ item.start_date | formatDate }} - {{ item.end_date | formatDate }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn @click="getBilletsByPartner(item)" x-small color="primary" class="pr-4">
                                    <span class="mr-2">+</span> Detalhes
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <AbranetReport />
                </v-tab-item>
                <v-tab-item>
                    <WorksheetConciliation />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <ReportByPartnerDialog :headerDetails="headerDetails" :billetsByPartner="billetsByPartner"
            :reportByPartnerDialog="reportByPartnerDialog" :toogleReportByPartnerDialog="toogleReportByPartnerDialog" />
    </div>
</template>
<script>
import MyFilters from "../../filters/genericFilters";
import * as moment from "moment";
import ReportByPartnerDialog from "./ReportByPartner.vue";
import AbranetReport from "./abranetReport/PartnersList.vue";
import WorksheetConciliation from "./WorksheetConciliation.vue";
export default {
    computed: {
        responsiveSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 220;
                case "sm":
                    return 400;
                case "md":
                    return 500;
                case "lg":
                    return 600;
                case "xl":
                    return 800;
            }
        },
    },
    beforeMount() {
        this.getHeightPage();
    },
    mounted() {
        // console.log(moment().format('YYYY-MM-DD'))
        this.verifyServices()
    },
    watch: {

    },
    methods: {
        verifyServices() {
            var obj = {
                url: "api/service/get-all",
                query: {},
                method: "get",
            }
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.disabTittles = true
                // this.tab = 1
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].prefix == "pagamento") {
                        this.getReport();
                        this.disabTittles = false
                        this.tab = 0
                    } else {

                    }
                }
            })
        },
        toogleReportByPartnerDialog() {
            this.reportByPartnerDialog = !this.reportByPartnerDialog
        },
        getBilletsByPartner(data) {
            var obj = {
                url: "/api/v1/summary/titles/getByPartner",
                query: {
                    'start_date': this.initialDate,
                    'end_date': this.endDate,
                    'partner_id': data.partner_id
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billetsByPartner = [...response.data.summaries]
                this.headerDetails = { ...data }
                this.toogleReportByPartnerDialog()
                //this.billets.push({ partner_name: "aqui" })
            })
        },
        getReport() {
            var obj = {
                url: "/api/v1/summary/titles/getPartner",
                query: {
                    'start_date': this.initialDate,
                    'end_date': this.endDate
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billets = [...response.data]
                //this.billets.push({ partner_name: "aqui" })
            })
        },
        getHeightPage() {
            this.windowH = window.innerHeight - 125;
        },
    },
    created() {
    window.addEventListener("resize", this.getHeightPage);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeightPage);
  },
    data() {
        return {
            windowH: "580",
            disabTittles: true,
            tab: 1,
            headerDetails: {},
            reportByPartnerDialog: false,
            billets: [],
            billetsByPartner: [],
            initialDate: "",
            initialDateMenu: false,
            endDate: "",
            endDateMenu: false,
            headers: [
                // 'partner_name'      => $user->name,
                // 'partner_document'  => formatDocument($user->document),
                // 'title_quantity'    => $value['total_quantity'],
                // 'title_total_value' => $value['total_liquided'],
                // 'start_date'        => $data['start_date'],
                // 'end_date'          => $data['end_date']
                { text: "Provedor", value: "partner_name", sortable: true },
                { text: "Qtd. de boletos", align: "center", value: "title_quantity", sortable: false },
                { text: "Valor total", value: "title_total_value", sortable: true },
                { text: "Período", value: "start_date", sortable: true },
                { text: "Ações", value: "actions", sortable: false }
            ],
        };
    },
    computed: {
        disableToday() {
            return moment().format('YYYY-MM-DD')
        },
        initialDateFormated: {
            get: function () {
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.initialDate = "";
                }
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        endDateFormated: {
            get: function () {
                let date = moment(this.endDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.endDate = "";
                }
                let date = moment(this.endDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    components: { ReportByPartnerDialog, AbranetReport, WorksheetConciliation },
};
</script>
  
<style></style>