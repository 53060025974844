<template>
    <v-row justify="center">
        <v-dialog light v-model="reportByPartnerDialog" persistent max-width="900px">
            <v-card>
                <v-card-title class="headline px-2">
                    <v-app-bar flat color="rgba(0, 0, 0, 0)">
                        <!-- // .partner_id, item.start_date, item.end_date partner_name partner_document -->
                        <v-toolbar-title class="text-h5 pl-0">
                            {{ headerDetails.partner_name }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-fab-transition> -->
                        <v-btn @click="toogleReportByPartnerDialog" class="mt-2" color="red" fab icon dark x-small absolute
                            top right>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <!-- </v-fab-transition> -->
                    </v-app-bar>
                </v-card-title>
                <v-card-subtitle v-if="tab == 0 ? true : false">
                    Boletos do períoddo de {{ headerDetails.start_date | formatDate }} a {{ headerDetails.end_date |
                        formatDate }}
                </v-card-subtitle>
                <v-card-subtitle v-if="tab == 1 ? true : false"
                    class="d-flex justify-space-between boxGrey font-weight-bold px-8 py-4 mb-4">
                    <v-btn style="height: 22px;" @click="tab = 0" x-small color="primary" class="pr-5">
                        <v-icon style="margin-bottom: 3px;" class="" small left>reply</v-icon> Voltar
                    </v-btn>
                    Boletos do dia {{ billetDay | formatDate }}
                </v-card-subtitle>
                <v-card-text>
                    <!-- summarization_date: "2023-02-27", quantity: 2, summarized_value: "1301.00 -->
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-data-table no-data-text="Não há boletos registrados" :headers="headers"
                                :items="billetsByPartner" :items-per-page="5" class="elevation-0">
                                <template v-slot:item.summarization_date="{ item }">
                                    {{ item.summarization_date | formatDate }}
                                </template>
                                <template v-slot:item.summarized_value="{ item }">
                                    R$ {{ item.summarized_value | formatPrice }}
                                </template>

                                <template v-slot:item.actions="{ item }">
                                    <v-btn @click="getBilletsBydate(item.summarization_date)" x-small color="primary"
                                        class="pr-4">
                                        <span class="mr-2">+</span> Detalhes
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>

                            <v-data-table no-data-text="Não há boletos registrados" :headers="headersByDate"
                                :items="billetsByDate" :items-per-page="5" class="elevation-0">
                                <template v-slot:item.due_date="{ item }">
                                    {{ item.due_date | formatDate }}
                                </template><template v-slot:item.payment_date="{ item }">
                                    {{ item.payment_date | formatDate }}
                                </template>
                                <template v-slot:item.value="{ item }">
                                    R$ {{ item.value | formatPrice }}
                                </template>

                                <!-- <template v-slot:item.actions="{ item }">
                                    <v-btn @click="getBilletsBydate(item.summarization_date)" x-small color="primary"
                                        class="pr-4">
                                        <span class="mr-2">+</span> Detalhes
                                    </v-btn>
                                </template> -->
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="toogleReportByPartnerDialog">
                        Fechar
                    </v-btn>
                    <!-- <v-btn color="green" text @click="">
                        Salvar
                    </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import MyFilters from "../../filters/genericFilters";
import * as moment from "moment";

export default {
    methods: {
        getBilletsBydate(date) {
            this.billetDay = date;
            var obj = {
                url: "/api/v1/summary/titles/getByDate",
                query: {
                    'summarization_date': date,
                    'partner_id': this.headerDetails.partner_id
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billetsByDate = [...response.data.titles]
                this.tab = 1
                //this.toogleReportByPartnerDialog()
                //this.billets.push({ partner_name: "aqui" })
            })
        }
    },
    watch: {
        reportByPartnerDialog(v) {
            if (!v) {
                this.tab = 0
            }
        }
    },
    data() {
        return {
            billetDay: "",
            tab: 0,
            billetsByDate: [],
            headersByDate: [
                { text: "Data de vencimento", /*align: "center",*/ value: "due_date", sortable: false },
                { text: "Data de pagamento", /*align: "center",*/ value: "payment_date", sortable: false },
                { text: "Número do boleto", value: "title_number", sortable: false },
                { text: "Valor do boleto", value: "value", sortable: false },
            ],
            headers: [
                // summarization_date: "2023-02-27", quantity: 2, summarized_value: "1301.00
                { text: "Data", value: "summarization_date", sortable: false },
                { text: "Qtd. de boletos totalizados", align: "center", value: "quantity", sortable: false },
                { text: "Valor total dos boletos", value: "summarized_value", sortable: false },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
    props: {
        headerDetails: Object,
        billetsByPartner: Array,
        reportByPartnerDialog: Boolean,
        toogleReportByPartnerDialog: Function
    },
}
</script>

<style>
.boxGrey {
    background-color: #efefef;
}
</style>