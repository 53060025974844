var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-10 pt-10 pb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-8 pl-2",attrs:{"cols":"12","md":"2"}},[_c('v-img',{staticClass:"mt-5",attrs:{"src":_vm.dtvGo,"max-width":"200"}})],1),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data inicial","readonly":""},model:{value:(_vm.initialDateFormated),callback:function ($$v) {_vm.initialDateFormated=$$v},expression:"initialDateFormated"}},on))]}}]),model:{value:(_vm.initialDateMenu),callback:function ($$v) {_vm.initialDateMenu=$$v},expression:"initialDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.initialDateMenu = false}},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data final","readonly":""},model:{value:(_vm.endDateFormated),callback:function ($$v) {_vm.endDateFormated=$$v},expression:"endDateFormated"}},on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","fab":"","color":"primary"},on:{"click":_vm.getReport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])])],1),_c('v-spacer'),(!_vm.abranetExcel)?_c('v-btn',{staticClass:"mr-2 mt-5",attrs:{"small":"","color":"success","dark":""},on:{"click":function($event){return _vm.generateExcel(false)}}},[_vm._v(" Gerar planilha"),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" assignment ")])],1):_vm._e(),(!_vm.abranetExcel)?_c('v-btn',{staticClass:"ml-5 mr-2 mt-5",attrs:{"small":"","color":"teal darken-2","dark":""},on:{"click":function($event){return _vm.generateExcel(true)}}},[_vm._v(" Extrato DTVgo"),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" receipt_long ")])],1):_vm._e(),(_vm.showDetailsReport)?_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('v-alert',{attrs:{"icon":"today","dense":"","text":"","type":"success"}},[_c('v-row',{staticClass:"grey--text text--darken-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_vm._v(" Relatório de pagamento DirectvGo, período de referência - "),_c('strong',[_vm._v(_vm._s(_vm.initialDateFormated)+" a "+_vm._s(_vm.endDateFormated))])]),_c('v-spacer'),_c('span',[_c('small',[_vm._v("Total: ")]),_c('strong',[_vm._v("R$ "+_vm._s(_vm._f("formatPrice")(_vm.subscriberSum)))])])],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"mt-3 px-3",attrs:{"cols":"12","md":"12"}},[_c('h3',[_vm._v("Subscrições relativas ao período de referência")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }