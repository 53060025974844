<template>
    <div class="pl-4 pr-2 mb-3">
        <v-row no-gutters class="px-10 pt-0 pb-0 mb-5">
            <!-- <v-col cols="12" md="2" class="pl-2 pr-3">
                <v-menu v-model="initialDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field clearable v-model="initialDateFormated" label="Data inicial" readonly
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="disableToday" no-title v-model="initialDate"
                        @input="initialDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="pl-2 pr-3">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field clearable v-model="endDateFormated" label="Data final" readonly
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="mt-3">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="getReport" class="ma-2" x-small fab color="primary">
                            <v-icon small>search</v-icon>
                        </v-btn>
                    </template>
                    <span>Pesquisar</span>
                </v-tooltip>
            </v-col>
            <v-col cols="12" md="3" class="mt-0"></v-col> -->

            <v-col cols="12" md="3" class="mt-2 pt-5">
                <h3 class="pl-2">Lista de provedores</h3>
            </v-col>
            <v-col cols="12" md="3" class="mt-0"></v-col>
            <v-col cols="12" md="3" class="mt-0"></v-col>
            <v-col cols="12" md="3" class="mt-0">
                <v-text-field :disabled="partners.length ? false : true" v-model="search" append-icon="filter_alt"
                    label="Filtrar" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        <div class="px-10">
            <v-data-table no-data-text="Não há boletos registrados para o período" :headers="headers" :items="partners"
                :items-per-page="5" :search="search" class="elevation-0">

                <template v-slot:item.name="{ item }">
                    <div>
                        {{ item.name }}
                    </div>
                    <div class="caption">{{ item.document | formatCnpjCpf }}</div>
                </template>


                <template v-slot:item.actions="{ item }">
                    <v-btn @click="toogleSpreadsheetScreenDialog(item)" x-small color="primary" class="pr-4">
                        <span class="mr-2">+</span> Detalhes
                    </v-btn>
                </template>
                <!-- <template v-slot:item.partner_name="{ item }">
                    <div>
                        {{ item.partner_name }}
                    </div>
                    <div class="caption">{{ item.partner_document | formatCnpjCpf }}</div>
                </template>

                <template v-slot:item.title_total_value="{ item }">
                    R$ {{ item.title_total_value | formatPrice }}
                </template>

                <template v-slot:item.start_date="{ item }">
                    {{ item.start_date | formatDate }} - {{ item.end_date | formatDate }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn @click="getBilletsByPartner(item)" x-small color="primary" class="pr-4">
                        <span class="mr-2">+</span> Detalhes
                    </v-btn>
                </template> -->
            </v-data-table>
        </div>
        <SpreadsheetScreen :abranetExcel="abranetExcel" :externalPartnerId="externalPartnerId"
            externalUrl="/api/partner/abranet/get-report" :spreadsheetScreenDialog="spreadsheetScreenDialog"
            :toogleSpreadsheetScreenDialog="toogleSpreadsheetScreenDialog" />
    </div>
</template>

<script>
import * as moment from "moment";
import MyFilters from "../../../filters/genericFilters";
import SpreadsheetScreen from "../../clients/financial/directvGo/SpreadsheetScreen.vue";
export default {
    methods: {
        toogleSpreadsheetScreenDialog(item) {
            this.externalPartnerId = item.id
            this.spreadsheetScreenDialog = !this.spreadsheetScreenDialog;
        },
        getReport() {
            var obj = {
                url: "/api/partner/abranet/get-all",
                query: {},
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // console.log('headers', response.data[0])
                this.partners = [...response.data]
                //this.billets.push({ partner_name: "aqui" })
            })
        }
    },
    data() {
        return {
            abranetExcel: true,
            externalPartnerId: null,
            spreadsheetScreenDialog: false,
            initialDate: "",
            initialDateMenu: false,
            endDate: "",
            endDateMenu: false,
            search: "",
            partners: [],
            headers: [
                { text: "Provedor", value: "name", sortable: true },
                { text: "E-mail", value: "email", sortable: true },
                { text: "Telefone", value: "phone", sortable: false },
                { text: "Ações", value: "actions", sortable: false }
                // { text: "Valor total", align: "center",  value: "title_total_value", sortable: true },
                // { text: "Período", value: "start_date", sortable: true },
                // { text: "Ações", value: "actions", sortable: false }
            ],
        }
    },
    mounted() {
        this.getReport();
    },
    computed: {
        disableToday() {
            return moment().format('YYYY-MM-DD')
        },
        initialDateFormated: {
            get: function () {
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.initialDate = "";
                }
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        endDateFormated: {
            get: function () {
                let date = moment(this.endDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.endDate = "";
                }
                let date = moment(this.endDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    components: { SpreadsheetScreen },

}
</script>

<style lang="scss" scoped></style>