var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-row',{staticClass:"px-3 pt-0 pb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"clearable":"","item-text":"name","item-value":"val","items":_vm.statusTypes,"label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data inicial","readonly":""},model:{value:(_vm.initialDateFormated),callback:function ($$v) {_vm.initialDateFormated=$$v},expression:"initialDateFormated"}},on))]}}]),model:{value:(_vm.initialDateMenu),callback:function ($$v) {_vm.initialDateMenu=$$v},expression:"initialDateMenu"}},[_c('v-date-picker',{attrs:{"max":_vm.disableToday,"no-title":""},on:{"input":function($event){_vm.initialDateMenu = false}},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data final","readonly":""},model:{value:(_vm.endDateFormated),callback:function ($$v) {_vm.endDateFormated=$$v},expression:"endDateFormated"}},on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","fab":"","color":"primary"},on:{"click":_vm.getReport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"item-key":"id","show-expand":"","single-expand":"","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.protocols,"items-per-page":5},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.status ? 'Arquivo enviado' : 'Erro no envio')+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",staticStyle:{"cursor":"pointer"},attrs:{"color":item.status ? 'primary' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}],null,true)},[_c('span',[_vm._v("Action: "+_vm._s(item.action))])])],1),_c('div',[_c('small',[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.event_date.split(" ")[0])))]),_vm._v(" - "),_c('span',[_vm._v(" "+_vm._s(item.event_date.split(" ")[1]))])])])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-2"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",staticStyle:{"cursor":"pointer"},attrs:{"color":item.status ? 'primary' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.message_ftp))])])],1),_c('td',{attrs:{"width":"400"}},[_vm._v(" "+_vm._s(item.message)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:['btMicro px-1 mr-3',
                        item.status ? 'disableBt' : '' ],attrs:{"x-small":"","color":"primary","dark":"","disabled":item.status ? true : false},on:{"click":function($event){return _vm.resendFile(item.file, item.protocol_number)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" reply ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Reenviar arquivo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":"teal darken-2","dark":""},on:{"click":function($event){return _vm.openFile(item.link)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" save_alt ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Download do arquivo")])])]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [_c('td',{staticClass:"px-2 py-4",attrs:{"colspan":headers.length}},[(!item.history.length)?_c('v-row',{staticClass:"px-0 py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"4"}},[_c('small',[_vm._v(" Não há histórico registrado ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}})],1):_vm._e(),(item.history.length)?_c('v-row',{staticClass:"px-0 py-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"px-8 pt-1 pb-3"},[_vm._v("Histórico de envio do arquivo")])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-0 px-5",attrs:{"hide-default-footer":"","headers":_vm.historyHeader,"items":item.history,"item-key":"id"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.status ? 'Arquivo enviado' : 'Erro no envio')+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",staticStyle:{"cursor":"pointer"},attrs:{"color":item.status ? 'primary' : 'error',"x-small":""}},'v-icon',attrs,false),on),[_vm._v("info")])]}}],null,true)},[_c('span',[_vm._v("Action: "+_vm._s(item.action))])])],1)]}},{key:"item.event_date",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.event_date.split(" ")[0]))+" - "+_vm._s(item.event_date.split(" ")[1])+" ")]}}],null,true)})],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }