var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-16 pr-2 mb-3"},[_c('v-card',{staticClass:"mx-auto mt-3 bgTransparente",attrs:{"min-height":_vm.windowH,"max-width":"calc(100% - 10px)","elevation":"0"}},[_c('v-card-title',{staticClass:"ml-10 pt-4 pb-8 pl-0 black--text"},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"disabled":_vm.disabTittles}},[_vm._v("Relatório gerencial de boletos")]),_c('v-tab',[_vm._v("Relatório gerencial de subscrições")]),_c('v-tab',[_vm._v("Protocolo de envio conciliação DGO")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"px-10 pt-0 pb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data inicial","readonly":""},model:{value:(_vm.initialDateFormated),callback:function ($$v) {_vm.initialDateFormated=$$v},expression:"initialDateFormated"}},on))]}}]),model:{value:(_vm.initialDateMenu),callback:function ($$v) {_vm.initialDateMenu=$$v},expression:"initialDateMenu"}},[_c('v-date-picker',{attrs:{"max":_vm.disableToday,"no-title":""},on:{"input":function($event){_vm.initialDateMenu = false}},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1),_c('v-col',{staticClass:"pl-2 pr-3",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"Data final","readonly":""},model:{value:(_vm.endDateFormated),callback:function ($$v) {_vm.endDateFormated=$$v},expression:"endDateFormated"}},on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","fab":"","color":"primary"},on:{"click":_vm.getReport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])])],1)],1),_c('div',{staticClass:"px-10"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"no-data-text":"Não há boletos registrados para o período","headers":_vm.headers,"items":_vm.billets,"items-per-page":5},scopedSlots:_vm._u([{key:"item.partner_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.partner_name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("formatCnpjCpf")(item.partner_document)))])]}},{key:"item.title_total_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.title_total_value))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" - "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pr-4",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.getBilletsByPartner(item)}}},[_c('span',{staticClass:"mr-2"},[_vm._v("+")]),_vm._v(" Detalhes ")])]}}])})],1)],1),_c('v-tab-item',[_c('AbranetReport')],1),_c('v-tab-item',[_c('WorksheetConciliation')],1)],1)],1),_c('ReportByPartnerDialog',{attrs:{"headerDetails":_vm.headerDetails,"billetsByPartner":_vm.billetsByPartner,"reportByPartnerDialog":_vm.reportByPartnerDialog,"toogleReportByPartnerDialog":_vm.toogleReportByPartnerDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }