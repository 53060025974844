<template>
    <div class="px-10">
        <v-row no-gutters class="px-3 pt-0 pb-5">
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-select clearable item-text="name" item-value="val" v-model="status" :items="statusTypes"
                    label="Status"></v-select>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-menu v-model="initialDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field clearable v-model="initialDateFormated" label="Data inicial" readonly
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="disableToday" no-title v-model="initialDate"
                        @input="initialDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3" class="pl-2 pr-3">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field clearable v-model="endDateFormated" label="Data final" readonly
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="mt-3">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" @click="getReport" class="ma-2" x-small fab color="primary">
                            <v-icon small>search</v-icon>
                        </v-btn>
                    </template>
                    <span>Pesquisar</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-data-table item-key="id" show-expand single-expand :expanded.sync="expanded" :headers="headers"
            :items="protocols" :items-per-page="5" class="elevation-0">
            <template v-slot:item.status="{ item }">
                <div>
                    {{ item.status ? 'Arquivo enviado' : 'Erro no envio' }}
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mb-1" style="cursor: pointer;" :color="item.status ? 'primary' : 'error'"
                                v-bind="attrs" v-on="on" x-small>info</v-icon>
                        </template>
                        <span>Action: {{ item.action }}</span>
                    </v-tooltip>
                </div>
                <div> <small><span>{{ item.event_date.split(" ")[0] | formatDate }}</span> - <span> {{
                    item.event_date.split(" ")[1]
                }}</span></small></div>
            </template>
            <template v-slot:item.message="{ item }">
                <td class="pr-2">
                    <v-tooltip bottom max-width="350">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mb-1" style="cursor: pointer;" :color="item.status ? 'primary' : 'error'"
                                v-bind="attrs" v-on="on" x-small>info</v-icon>
                        </template>
                        <span>{{ item.message_ftp }}</span>
                    </v-tooltip>
                </td>
                <td width="400">
                    {{ item.message }}
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :class="['btMicro px-1 mr-3',
                            item.status ? 'disableBt' : '',
                        ]" @click="resendFile(item.file, item.protocol_number)" x-small color="primary" dark
                            :disabled="item.status ? true : false"><v-icon center x-small> reply
                            </v-icon>
                        </v-btn>
                    </template>
                    <span class="miniTtip">Reenviar arquivo</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-3" @click="openFile(item.link)" x-small
                            color="teal darken-2" dark><v-icon center x-small> save_alt </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Download do arquivo</span>
                </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="px-2 py-4" :colspan="headers.length">
                    <v-row no-gutters class="px-0 py-0" v-if="!item.history.length">
                        <v-col cols="12" md="4" class="pl-5"><small> Não há histórico registrado </small></v-col>
                        <v-col cols="12" md="4" class=""></v-col>
                        <v-col cols="12" md="4" class=""></v-col>
                    </v-row>
                    <v-row v-if="item.history.length" no-gutters class="px-0 py-0">
                        <v-col cols="12" md="12" class="">
                            <h3 class="px-8 pt-1 pb-3">Histórico de envio do arquivo</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="">
                            <v-data-table hide-default-footer :headers="historyHeader" :items="item.history" item-key="id"
                                class="elevation-0 px-5">

                                <template v-slot:item.status="{ item }">
                                    <div>
                                        {{ item.status ? 'Arquivo enviado' : 'Erro no envio' }}
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon class="mb-1" style="cursor: pointer;"
                                                    :color="item.status ? 'primary' : 'error'" v-bind="attrs" v-on="on"
                                                    x-small>info</v-icon>
                                            </template>
                                            <span>Action: {{ item.action }}</span>
                                        </v-tooltip>
                                    </div>

                                </template>
                                <template v-slot:item.event_date="{ item }">
                                    {{ item.event_date.split(" ")[0] | formatDate }} - {{ item.event_date.split(" ")[1] }}
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import * as moment from "moment";
export default {
    methods: {
        handleExpansion(item, state) {
            // console.log(item, state);
            this.expanded = [];
            const itemIndex = this.expanded.indexOf(item);
            state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
        },
        openFile(url) {
            window.open(url, '_blank')
        },
        resendFile(filename, protocol_number) {
            let data = {
                file: filename,
                protocol_number: protocol_number
            }
            var obj = {
                url: "/api/conciliation/protocol/report/resend",
                query: data,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) return;
                let opts = {
                    message: response.data.message,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getReport()
            });
        },
        getReport() {

            if (!this.initialDate || !this.endDate) { return }
            let stat
            if (this.status === 1) {
                stat = "true"
            } else if (this.status === 0) {
                stat = "false"
            } else {
                stat = ""
            }
            var dataQuery = {
                "start_date": this.initialDate,
                "end_date": this.endDate,
                "status": stat
            }
            var obj = {
                url: "/api/conciliation/protocol/history/get-all",
                query: dataQuery,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                let data = response.data.map(item => {
                    let formatjson = JSON.parse(item.return)
                    item.message = formatjson.message
                    return item
                })
                this.protocols = [...data]
                // this.errorFailedJobsList = [...response.data.data];
                // this.totalPages = response.data.total_page;
                // //this.setedPage = true;
                // this.actualPage = parseFloat(response.data.current_page);
            });
        }
    },
    data() {
        return {
            expanded: [],
            singleExpand: false,
            historyHeader: [
                {
                    text: "Status",
                    value: "status",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Data do envio",
                    value: "event_date",
                    align: "start",
                    sortable: false,
                },

                {
                    text: "Mensagem",
                    value: "message_ftp",
                    align: "start",
                    sortable: false,
                },
            ],
            headers: [
                {
                    text: "Status",
                    value: "status",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "Protocolo",
                    value: "protocol_number",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "Nome do arquivo",
                    value: "file",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "Mensagem",
                    value: "message",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "start",
                    sortable: false,
                }
            ],
            status: "",
            statusTypes: [
                { val: 1, name: 'Enviados' },
                { val: 0, name: 'Não enviados' }
            ],
            protocols: [],
            initialDate: "",
            initialDateMenu: false,
            endDate: "",
            endDateMenu: false,
        }
    },
    computed: {
        disableToday() {
            return moment().format('YYYY-MM-DD')
        },
        initialDateFormated: {
            get: function () {
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.initialDate = "";
                }
                let date = moment(this.initialDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        endDateFormated: {
            get: function () {
                let date = moment(this.endDate).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.endDate = "";
                }
                let date = moment(this.endDate).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
}
</script>

<style>
.v-btn.v-btn--disabled.btMicro.disableBt {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    opacity: 0.4 !important;
}
</style>